// import { createStore as reduxCreateStore, applyMiddleware } from "redux"
import logger from "redux-logger"

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import globalReducer from './globalSlice'
import scrollerReducer from './scrollerSlice'

// const createStore = () => reduxCreateStore(rootReducer, applyMiddleware(logger));
// const createStore = () => reduxCreateStore(rootReducer);

const rootReducer = combineReducers({
  global: globalReducer,
  scroller: scrollerReducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});


export default store;
