import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sceneNumber: 0,
  loading: true,
  muted: false,
  visible: false

};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    initialLoad: (state) => {
      return { ...state, sceneNumber: 0, loading: false };
    },
    loginSuccess: (state) => {
      return { ...state, sceneNumber: 1, loading: false };
    },
    mute: (state) => {
      state.muted = !state.muted;
      return;
    },
    moveTo: (state, action) => {
      state.visible = false;
      state.sceneNumber = action.payload;
      state.loading = true;
      return;
    },
    moveForward: (state) => {
      state.visible = false;
      state.sceneNumber++;
      state.loading = true;
      return;
    },
    moveBack: (state, action) => {
      state.visible = false;
      state.sceneNumber > 0 && state.sceneNumber--;
      state.loading = true;
      return;
    },
    loaded: (state, action) => {
      return { ...state, loading: false, visible: false };
    },
    visible: (state, action) => {
      return { ...state, visible: true };
    },
  },
});

// Action creators are generated for each case reducer function
export const { initialLoad, loginSuccess, moveTo, moveForward, moveBack, loaded, mute, visible } = globalSlice.actions;

export default globalSlice.reducer;
