import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  activeSection: 0,
  canScroll: [false, false, false],
  paging: false
};

export const scrollerSlice = createSlice({
  name: "scroller",
  initialState,
  reducers: {
    setActiveSection: (state, action) => {
      state.activeSection = action.payload.section;
      state.paging = true;
      return;
    },
    setScrollFalse: (state, action) => {
      state.canScroll[state.activeSection] = false;
      return;
    },
    setScroll: (state, action) => {
      state.canScroll[state.activeSection] = true;
      return;
    },
    resetSections: (state, action) => {
      state.canScroll = initialState.canScroll;
      state.paging = false;
      return;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setActiveSection, setScroll, resetSections, setScrollFalse } = scrollerSlice.actions;

export default scrollerSlice.reducer;
