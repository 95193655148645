// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-js": () => import("./../../../src/pages/[...].js" /* webpackChunkName: "component---src-pages-js" */),
  "component---src-pages-loader-js": () => import("./../../../src/pages/loader.js" /* webpackChunkName: "component---src-pages-loader-js" */),
  "component---src-pages-panels-js": () => import("./../../../src/pages/panels.js" /* webpackChunkName: "component---src-pages-panels-js" */),
  "component---src-pages-take-it-easy-text-js": () => import("./../../../src/pages/take-it-easy-text.js" /* webpackChunkName: "component---src-pages-take-it-easy-text-js" */)
}

