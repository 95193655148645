/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react";
// You can delete this file if you're not using it
import "./src/components/fonts.css"


import { Provider } from 'react-redux';
import store from './src/state/store';



export const wrapRootElement = ({ element }) => {
    return <Provider store={store}>{element}</Provider>;
  };